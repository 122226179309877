import { Tabs } from '@allurion/ui';
import { strToDate } from '@allurion/utils';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ScaleDataLineChart } from 'src/charts/ScaleDataLineChart/ScaleDataLineChart';
import { PatientWeight } from 'src/domain/patient/Patients';
import { fixNumbers, getUnitFactor } from 'src/helpers/convertions';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { usePatientWeight } from 'src/hooks/usePatientWeight';

import messages from './datamenu-messages';

import styles from './PatientScaleData.module.scss';

type Props = {
  patientId: string;
};

export function PatientScaleDataProgressChart({ patientId }: Props) {
  const intl = useIntl();
  const { weightData: patientWeight } = usePatientWeight(patientId);
  const { weightUnit } = useCurrentProvider();

  const dataItems = useMemo(
    () => [
      {
        label: intl.formatMessage(messages.weight),
        id: 'weight',
        units: weightUnit,
      },
      {
        label: intl.formatMessage(messages.bmi),
        id: 'BMI',
        units: '',
      },
      {
        label: intl.formatMessage(messages.bodyFat),
        id: 'bodyFatMassPerc',
        units: '%',
      },
      {
        label: intl.formatMessage(messages.bodyFatMass),
        id: 'bodyFatMass',
        units: weightUnit,
      },
      {
        label: intl.formatMessage(messages.leanBodyMassPerc),
        id: 'leanBodyMassPerc',
        units: '%',
      },
      {
        label: intl.formatMessage(messages.leanBodyMass),
        id: 'leanBodyMass',
        units: weightUnit,
      },
      {
        label: intl.formatMessage(messages.bmr),
        id: 'BMR',
        units: 'kcal',
      },
    ],
    [intl, weightUnit]
  );

  const [activeItem, setActiveItem] = useState(dataItems[0]);
  let unitFactor = 1.0;

  // apply unit factor only to Weight
  if (['weight', 'bodyFatMass', 'leanBodyMass'].includes(activeItem.id)) {
    unitFactor = getUnitFactor(weightUnit);
  }

  const formattedData = useMemo(() => {
    if (!patientWeight) {
      return [];
    }

    const weightEntriesByDate = patientWeight.reduce(
      (acc, entry) => {
        const date = entry.entryDate;

        return {
          ...acc,
          [date]: [...(acc[date] || []), entry],
        };
      },
      {} as Record<string, PatientWeight[]>
    );

    const avgEntries = (Object.values(weightEntriesByDate) as PatientWeight[][]).map((entries) => {
      const metrics: Record<string, number> = {};

      [
        'weight',
        'BMI',
        'bodyFatMass',
        'bodyFatMassPerc',
        'leanBodyMass',
        'leanBodyMassPerc',
        'BMR',
      ].forEach((attribute) => {
        const isPerc = attribute.includes('Perc');
        const values = entries
          .map((entry) => (entry[attribute] ? parseFloat(entry[attribute]) : null))
          .filter(Boolean) as number[];
        const sum = values.reduce((s, b) => s + (isPerc ? b * 100 : b), 0);
        const avg = sum / values.length || 0;

        metrics[attribute] = avg;
      });

      return {
        entryDate: strToDate(entries[0].entryDate),
        ...metrics,
      };
    });

    return [...avgEntries]
      .sort((a, b) => {
        return a.entryDate.getTime() - b.entryDate.getTime();
      })
      .map((data) => ({
        x: data.entryDate.getTime(),
        y: fixNumbers((data as any)[activeItem.id], 1, unitFactor),
      }));
  }, [activeItem.id, patientWeight, unitFactor]);

  return (
    <div style={{ marginRight: '20px' }}>
      <div className={styles.progress__tabsWrapper} style={{ marginBottom: '36px' }}>
        <Tabs
          direction="row"
          tabs={dataItems}
          selectedTabId={activeItem.id}
          onTabChange={(tab) => setActiveItem(dataItems.find((item: any) => tab.id === item.id)!)}
        />
      </div>
      <ScaleDataLineChart
        id="scale-data-line-chart"
        data={formattedData}
        units={activeItem.units}
      />
    </div>
  );
}
