import { useContentfulLocaleOrFallback } from '@allurion/contentful';
import {
  formatWeight,
  isManualWeightEntryEnabled,
  isPatientAtRisk,
  isRiskAnalyisEnabled,
} from '@allurion/domain';
import { Card, InfoIcon } from '@allurion/ui';
import { isNotNull } from '@allurion/utils';
import { useIntl } from 'react-intl';

import { EditStartingWeight } from 'src/components/WeightEntry/EditStartingWeight';
import { ClinicSettings } from 'src/domain/Clinic';
import { PatientOverview, PatientProfile } from 'src/domain/patient/Patients';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { useLocale } from 'src/hooks/useLocale';
import translations from 'src/messages/translations';

import { SuccessPredictorCard } from './SuccessPredictorCard';

import styles from './PatientProgressOverviewCard.module.scss';

const contentfulValidLocales = [
  'en-GB',
  'ar',
  'ca',
  'zh-CN',
  'zh-Hant-TW',
  'da',
  'nl',
  'en-US',
  'fr',
  'de',
  'it',
  'pt-BR',
  'es-419',
  'es-ES',
  'sv',
  'tr',
];

type Props = {
  patientId: string;
  patient: PatientProfile;
  data?: PatientOverview;
  settings: ClinicSettings;
  reloadData: () => void;
};

export function PatientProgressOverviewCard({
  data,
  settings,
  patient,
  patientId,
  reloadData,
}: Props) {
  const intl = useIntl();
  const { locale } = useLocale();
  const contentfulLocale = useContentfulLocaleOrFallback(contentfulValidLocales, locale);
  const { weightUnit } = useCurrentProvider();
  const {
    starting_weight,
    current_weight,
    weight_loss_since_placement,
    perc_weight_loss_since_placement,
    starting_bmi,
    current_bmi,
    weeks_in_program,
    weeks_weighed_in,
    starting_lean_body_mass,
    current_lean_body_mass,
    starting_body_fat,
    current_body_fat,
  } = data ?? {};
  const startingWeight = formatWeight(starting_weight, weightUnit);
  const currentWeight = formatWeight(current_weight, weightUnit);
  const totalWeightLoss = formatWeight(weight_loss_since_placement, weightUnit);
  const isAtRiskEnabled = isRiskAnalyisEnabled(settings);
  const isAtRisk = isPatientAtRisk(patient.at_risk_score, patient.at_risk_display);
  const shouldRenderSuccessPreditorCard = isAtRiskEnabled && isNotNull(isAtRisk);
  const isPatientActive = patient.active_patient;

  const currentBodyFatPercentage =
    current_body_fat && current_weight && (current_body_fat / current_weight) * 100;
  const startingBodyFatPercentage =
    starting_body_fat && starting_weight && (starting_body_fat / starting_weight) * 100;
  const totalLeanBodyMassLoss =
    starting_lean_body_mass &&
    current_lean_body_mass &&
    starting_lean_body_mass - current_lean_body_mass;

  function openOverviewNotes(): void {
    window.open(
      `https://cms.allurion.com/${contentfulLocale}/insights-patient-overview-interpretation-notes`,
      '_blank'
    );
  }

  return (
    <Card
      title={intl.formatMessage(translations.progressOverview)}
      iconButtons={[
        {
          icon: <InfoIcon />,
          onClick: openOverviewNotes,
          tooltip: intl.formatMessage(translations.overviewNotesTooltip),
        },
      ]}
    >
      {shouldRenderSuccessPreditorCard && (
        <SuccessPredictorCard
          isAtRisk={isAtRisk}
          at_risk_display={patient.at_risk_display}
          at_risk_score={patient.at_risk_score}
        />
      )}

      <div className={styles.metrics}>
        <Metric
          label={intl.formatMessage(translations.clinicOverviewStartingWeight)}
          value={startingWeight}
          action={
            isManualWeightEntryEnabled(settings) && (
              <EditStartingWeight patientId={patientId} afterSave={reloadData} />
            )
          }
        />
        <Metric
          label={intl.formatMessage(
            isPatientActive
              ? translations.clinicOverviewCurrentWeight
              : translations.clinicOverviewEndingWeight
          )}
          value={currentWeight}
        />

        <Metric
          label={intl.formatMessage(translations.clinicOverviewTotalWeightLoss)}
          value={`${perc_weight_loss_since_placement?.toFixed(1) ?? '-'}%`}
          extraValue={`${totalWeightLoss ?? '-'}`}
          dotSeparator
        />
        <Metric
          label={intl.formatMessage(translations.clinicOverviewWeightLossFromLeanBodyMass)}
          value={`${totalLeanBodyMassLoss?.toFixed(1) ?? '-'} ${weightUnit}`}
        />

        <Metric
          label={intl.formatMessage(translations.clinicOverviewStartingBmi)}
          value={`${starting_bmi?.toFixed(1) ?? '-'}`}
        />
        <Metric
          label={intl.formatMessage(
            isPatientActive
              ? translations.clinicOverviewCurrentBmi
              : translations.clinicOverviewEndingBmi
          )}
          value={`${current_bmi?.toFixed(1) || 0}`}
        />

        <Metric
          label={intl.formatMessage(translations.clinicOverviewStartingBodyFat)}
          value={`${startingBodyFatPercentage?.toFixed(1) ?? '-'}%`}
          extraValue={`${starting_body_fat?.toFixed(1) ?? '-'} ${weightUnit}`}
          dotSeparator
        />
        <Metric
          label={intl.formatMessage(translations.clinicOverviewCurrentBodyFat)}
          value={`${currentBodyFatPercentage?.toFixed(1) ?? '-'}%`}
          extraValue={`${current_body_fat?.toFixed(1) ?? '-'} ${weightUnit}`}
          dotSeparator
        />

        <Metric
          label={intl.formatMessage(translations.clinicOverviewWeeksInProgram)}
          value={`${weeks_in_program?.toFixed(0) ?? '-'}`}
        />
        <Metric
          label={intl.formatMessage(translations.clinicOverviewWeeksOneWeighIn)}
          value={`${weeks_weighed_in?.toFixed(0) ?? '-'}`}
        />
      </div>
    </Card>
  );
}

type MetricProps = {
  label: string;
  value: string;
  extraValue?: string;
  dotSeparator?: boolean;
  action?: React.ReactNode;
};

function Metric({ label, value, action, extraValue, dotSeparator }: MetricProps) {
  return (
    <div className={styles.metric}>
      <div className={styles.metricContent}>
        <span className={styles.metricLabel}>{label}</span>
        <span className={styles.metricValues}>
          <span className={styles.metricValue}>{value}</span>
          {extraValue && (
            <span className={styles.metricExtraValue}>
              {dotSeparator ? <>&nbsp;&nbsp;•&nbsp;&nbsp;</> : ' '}
              {extraValue}
            </span>
          )}
        </span>
      </div>
      {action && <span className={styles.metricAction}>{action}</span>}
    </div>
  );
}
