import { isNull } from './is-type';

export function formatPercentage(value: number | null, decimals = 1) {
  if (value == null) {
    return '-';
  }

  return `${(value * 100).toFixed(decimals)}%`;
}

export function nullIfNotPositive(value: number | null) {
  if (isNull(value)) {
    return null;
  }

  return value > 0 ? value : null;
}
