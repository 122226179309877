import { isExportDataEnabled, isManualWeightEntryEnabled } from '@allurion/domain';
import { Card, LineChartIcon, Tooltip } from '@allurion/ui';
import { useIntl } from 'react-intl';

import { TrackedEmptyState, TrackedSortByMenu } from 'src/analytics/TrackedUI';
import { exportPatientData } from 'src/api/ExportApi';
import { DownloadDataBtn } from 'src/components/DownloadDataBtn';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { AddWeightEntry } from 'src/components/WeightEntry/AddWeightEntry';
import { PatientWeight } from 'src/domain/patient/Patients';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { usePatientProfile } from 'src/hooks/usePatientProfile';
import { usePatientWeight } from 'src/hooks/usePatientWeight';
import globalMessages from 'src/messages/global.messages';
import { useTableSort } from 'src/ui/useTableSort';

import messages from './datamenu-messages';
import { PatientScaleDataProgressChart } from './PatientScaleDataProgressChart';
import { PatientScaleDataTable } from './PatientScaleDataTable';

import styles from './PatientScaleData.module.scss';

type Props = {
  patientId: string;
  reloadData: () => void;
};

export function PatientScaleData({ patientId, reloadData }: Props) {
  const { weightData: patientWeightData, isLoading } = usePatientWeight(patientId);
  const { isAdmin } = useCurrentProvider();
  const { patient } = usePatientProfile(patientId);
  const clinicId = patient?.hospitalid.toString();
  const { settings } = useClinicSettings(clinicId);
  const intl = useIntl();

  const exportData = () => exportPatientData(patientId, navigator.language);

  const {
    sortedData: sortedPatientWeightData,
    sortBy,
    setSortBy,
  } = useTableSort<PatientWeight>(patientWeightData, '-entryDate', {
    'weight': 'number',
    'BMI': 'number',
    'bodyFatMassPerc': 'number',
    'bodyFatMass': 'number',
    'leanBodyMass': 'number',
    'leanBodyMassPerc': 'number',
    'BMR': 'number',
  });

  const patientHasWeightData = patientWeightData?.length > 0;

  return (
    <div className={styles.container}>
      <Card
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {intl.formatMessage({ id: 'weightData.progress.title', defaultMessage: 'Progress' })}
            </div>
            <div>{isLoading && <InlineLoader size="14px" />}</div>
          </div>
        }
      >
        {patientHasWeightData ? (
          <PatientScaleDataProgressChart patientId={patientId} />
        ) : isLoading ? (
          <InlineLoader />
        ) : (
          <TrackedEmptyState
            trackedLabel="no-patient-scale-data-found"
            title={intl.formatMessage({
              id: 'scale-graph.no-data',
              defaultMessage: 'Scale data history will appear here',
            })}
            subtitle={intl.formatMessage({
              id: 'scale-graph.no-data-description',
              defaultMessage:
                'Ask the patient to weigh in on the Allurion scale or click to add a weight data.',
            })}
            icon={<LineChartIcon />}
            data-cy="empty-patient-scale-graph"
          />
        )}
      </Card>

      <Card
        title={
          <div className={styles.weightData__header}>
            <div className={styles.weightData__header__title}>
              {intl.formatMessage({
                id: 'weight-tab.weightData.title',
                defaultMessage: 'Weight data',
              })}
            </div>

            <div className={styles.weightData__header__buttons}>
              {isLoading && <InlineLoader size="14px" />}
              {isManualWeightEntryEnabled(settings) && (
                <Tooltip
                  text={intl.formatMessage({
                    id: 'weight-tab.weightData.addWeightEntry',
                    defaultMessage: 'Add weight entry',
                  })}
                  placement="bottom"
                >
                  <AddWeightEntry patientId={patientId} afterSave={reloadData} />
                </Tooltip>
              )}
              {isAdmin && isExportDataEnabled(settings) && patientHasWeightData && (
                <Tooltip
                  text={intl.formatMessage({
                    id: 'weight-tab.weightData.downloadData',
                    defaultMessage: 'Download data',
                  })}
                  placement="bottom"
                >
                  <DownloadDataBtn exportData={exportData} />
                </Tooltip>
              )}

              {patientHasWeightData && (
                <TrackedSortByMenu
                  trackLabel="patient-weight-sort-by"
                  selectedOption={sortBy}
                  onChange={onSortByUpdate}
                  size="xs"
                  variant="icon"
                  tooltipText={intl.formatMessage({
                    id: 'weight-tab.weightData.sortBy',
                    defaultMessage: 'Sort by',
                  })}
                  options={[
                    {
                      value: 'entryDate',
                      label: intl.formatMessage(globalMessages.date),
                    },
                    {
                      value: 'weight',
                      label: intl.formatMessage(messages.weight),
                    },
                    {
                      value: 'BMI',
                      label: intl.formatMessage(messages.bmi),
                    },
                    {
                      value: 'bodyFatPerc',
                      label: intl.formatMessage(messages.bodyFat),
                    },
                    {
                      value: 'bodyFatMass',
                      label: intl.formatMessage(messages.bodyFatMass),
                    },
                    {
                      value: 'leanBodyMassPerc',
                      label: intl.formatMessage(messages.leanBodyMassPerc),
                    },
                    {
                      value: 'leanBodyMass',
                      label: intl.formatMessage(messages.leanBodyMass),
                    },
                    {
                      value: 'BMR',
                      label: 'TBWL',
                    },
                  ]}
                />
              )}
            </div>
          </div>
        }
      >
        {patientHasWeightData ? (
          <PatientScaleDataTable
            sortedPatientWeightData={sortedPatientWeightData}
            sortBy={sortBy}
            setSortBy={setSortBy}
            patientId={patientId}
            clinicId={clinicId}
          />
        ) : isLoading ? (
          <InlineLoader />
        ) : (
          <TrackedEmptyState
            trackedLabel="no-patient-weight-data-found"
            title={intl.formatMessage({
              id: 'weight-tab.weightData.noData',
              defaultMessage: 'No weight data',
            })}
            subtitle={intl.formatMessage({
              id: 'weight-tab.weightData.noData.description',
              defaultMessage: 'Add a weight entry to see your progress.',
            })}
            icon={<LineChartIcon />}
            data-cy="empty-patient-scale-data"
          />
        )}
      </Card>
    </div>
  );

  function onSortByUpdate(value: string) {
    setSortBy(value);
  }
}
