import { formatWeight } from '@allurion/domain';
import { ChevronIcon, TrashCanIcon } from '@allurion/ui';
import { formatPercentage, toLocalizedDate } from '@allurion/utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { TrackedIconButton } from 'src/analytics/TrackedUI';
import { PatientWeight } from 'src/domain/patient/Patients';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { useLocale } from 'src/hooks/useLocale';

import messages from './datatable-messages';

import styles from './PatientScaleDataCards.module.scss';

type CardsProps = {
  data: PatientWeight[];
  deleteWeightEntry: (weightEntryId: string) => void;
};

type CardProps = {
  patientScaleData: PatientWeight;
  deleteWeightEntry: (weightEntryId: string) => void;
};

export function PatientScaleDataCards({ data, deleteWeightEntry }: CardsProps) {
  return (
    <div className={styles.cards}>
      {data.map((patientScaleData) => (
        <Card
          key={patientScaleData.UserInfoId}
          patientScaleData={patientScaleData}
          deleteWeightEntry={deleteWeightEntry}
        />
      ))}
    </div>
  );
}

function Card({ patientScaleData, deleteWeightEntry, ...props }: CardProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { locale } = useLocale();
  const intl = useIntl();
  const { weightUnit } = useCurrentProvider();

  return (
    <div className={styles.card} {...props}>
      <div className={styles.header}>
        <div className={styles.header__createdDate}>
          {toLocalizedDate(patientScaleData.entryDate, locale) || 'No Date'}
        </div>
        <div className={styles.header__content}>
          {formatWeight(patientScaleData.Weight, weightUnit)}
          <TrackedIconButton
            size="xs"
            variant="icon"
            onClick={() => setIsOpen(!isOpen)}
            icon={<ChevronIcon direction={isOpen ? 'up' : 'down'} />}
            trackLabel="show-patient-scale-data-details"
          />
        </div>
      </div>
      {isOpen && (
        <div className={styles.body}>
          <table>
            <tbody>
              <tr>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>{intl.formatMessage(messages.bmi)}</span>
                  </td>
                  <td>
                    <span className={styles.value}>{patientScaleData.BMI}</span>
                  </td>
                </td>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>{intl.formatMessage(messages.bmr)}</span>
                  </td>
                  <td>
                    <span className={styles.value}>{patientScaleData.BMR}</span>
                  </td>
                </td>
              </tr>
              <tr>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>
                      {intl.formatMessage(messages.bodyFatMassPerc)}
                    </span>
                  </td>
                  <td>
                    <span className={styles.value}>
                      {formatPercentage(patientScaleData.bodyFatMassPerc)}
                    </span>
                  </td>
                </td>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>{intl.formatMessage(messages.bodyFatMass)}</span>
                  </td>
                  <td>
                    <span className={styles.value}>
                      {formatWeight(patientScaleData.bodyFatMass, weightUnit)}
                    </span>
                  </td>
                </td>
              </tr>
              <tr>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>
                      {intl.formatMessage(messages.leanBodyMassPerc)}
                    </span>
                  </td>
                  <td>
                    <span className={styles.value}>
                      {formatPercentage(patientScaleData.leanBodyMassPerc)}
                    </span>
                  </td>
                </td>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>{intl.formatMessage(messages.leanBodyMass)}</span>
                  </td>
                  <td>
                    <span className={styles.value}>
                      {formatWeight(patientScaleData.leanBodyMass, weightUnit)}
                    </span>
                  </td>
                </td>
              </tr>
              <tr>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>
                      {intl.formatMessage(messages.weightSourceTypeColumn)}
                    </span>
                  </td>
                  <td>
                    <span className={styles.value}>{patientScaleData.weight_source_type}</span>
                  </td>
                </td>
              </tr>
              <div
                className={styles.actions}
                onClick={() => deleteWeightEntry(patientScaleData.UserInfoId.toString())}
              >
                <TrashCanIcon />
              </div>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
